<template>
  <div cumulative-container>
    <CSBreadcrumb style="overflow: hidden"></CSBreadcrumb>
    <!-- 条件查询框 -->
    <div class="filter-panel">
      <div
        style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
      >
        <input
          type="text"
          placeholder="搜索历史业主/租客"
          v-model="selectPut"
          style="
            color: #000;
            width: 220px;
            height: 30px;
            padding-left: 10px;
            border-radius: 4px;
            border: 1px solid #979797;
            margin-right: 20px;
          "
        />
      </div>
      <button class="btn btn-primary" @click="getLogList()">查询</button>
    </div>
    <!-- 列表显示框 -->
    <template v-if="this.rotateId == 1">
      <div class="result-panel" style="overflow: hidden">
          <CSTable :thead-top="0">
              <template v-slot:thead>
                  <tr>
                      <th>水表编号</th>
                      <th>关联房间</th>
                      <th>历史业主/租客</th>
                      <th>累计使用吨数</th>
                      <th>消耗金额</th>
                  </tr>
              </template>
              
              <template v-slot:tbody>
                  <tr
                      v-for="item in historyList"
                      :key="item.rid"
                  >
                      <td>
                          <span>{{ item.meterAddr }}</span>
                      </td>
                      <td>
                          <span>{{ item.correlationRoom }}</span>
                      </td>
                      <td>
                  <span class="btn-active" @click="getInformation(item)">{{
                          item.companyName
                      }}</span>
                      </td>
                      <td>
                  <span>{{
                          item.cumulativeNum ? item.cumulativeNum + "吨" : "-"
                      }}</span>
                      </td>
                      <td>
                  <span
                      class="btn-active"
                      @click="getCumulativeMony(true, item)"
                  >{{
                          item.cumulativeAmount ? item.cumulativeAmount + "元" : "-"
                      }}</span
                  >
                      </td>
                  </tr>
              </template>
          </CSTable>
          <Pagination name="pagination" componentName="Pagination"></Pagination>
      </div>
    </template>

    <template v-if="this.rotateId == 2">

      <div class="result-panel">
          <CSTable>
              <template v-slot:thead>
                  <tr>
                      <th>电表编号</th>
                      <th>关联房间</th>
                      <th>历史业主/租客</th>
                      <th>累计使用度数</th>
                      <th>消耗金额</th>
                  </tr>
              </template>
              <template v-slot:tbody>
                  <tr
                      v-for="item in historyList"
                      :key="item.rid"
                  >
                      <td>
                          {{ item.meterAddr }}
                      </td>
                      <td>
                          {{item.correlationRoom}}
                      </td>
                      <td>
                        <span class="btn-active" @click="getInformation(item)">
                            {{ item.companyName }}
                        </span>
                      </td>
                      <td>
                          {{
                              item.cumulativeNum ? item.cumulativeNum + "度" : "-"
                          }}
                      </td>
                      <td>
                          <span class="btn-active" @click="getCumulativeMony(false, item)">
                              {{item.cumulativeAmount ? item.cumulativeAmount + "元" : "-"}}
                          </span>
                      </td>
                  </tr>
              </template>
          </CSTable>
          <Pagination name="pagination" componentName="Pagination"></Pagination>
      </div>
    </template>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSTabBar from "@/components/common/CSTabBar";
import Pagination from "@/components/Pagination.vue";
import CSTable from "@/components/common/CSTable";
import CSSelect from "@/components/common/CSSelect";
import { HYDROPOWER } from "@/constant";

import {
  waterHistoryListUrl,
  hydropowerContractInformationUrl,
} from "@/requestUrl";

export default {
  data() {
    return {
      selectPut: "",
      rotateId: "",
      meterId: "", //水表ID
      companyName: "", //水表名称
      historyList: [], //数据列表
      InformLists: {}, //公司信息
    };
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.getLogList(pageNo);
    });
  },
  components: {
    CSTable,
    CSBreadcrumb,
    CSTabBar,
    Pagination,
    CSSelect,
  },
  created() {
    this.rotateId = this.$route.params.id;
    this.meterId = this.$route.params.meterId;
    this.getLogList();
  },
  activated(){
    window.addEventListener("keydown",this.getLogListDown);
  },
  deactivated(){
    window.removeEventListener('keydown',this.getLogListDown);
  },
  methods: {
    getLogListDown(e){
      if(e.keyCode == 13){
        this.getLogList();
      }
    },
    getCumulativeMony(id, item) {
      if (id) {
        this.$router.push({
          name: "Cumulative",
          params: {
            id: 3,
            companyName: item.companyName,
            rid: item.rid,
            type: 1,
            meterId: item.meterId,
          },
        });
      } else {
        this.$router.push({
          name: "Cumulative",
          params: {
            id: 4,
            companyName: item.companyName,
            rid: item.rid,
            type: 2,
            meterId: item.meterId,
          },
        });
      }
    },
    getLogList(pageNo = 1, pageSize = 10) {
      if (this.rotateId == 1) {
        this.$fly
          .post(waterHistoryListUrl, {
            meterId: this.meterId, //表id
            type: HYDROPOWER.WATER_METER,
            pageSize: pageSize, //页面大小
            pageNo: pageNo, //页码
            search: this.selectPut, //搜索框内容
          })
          .then((res) => {
            console.log(res);
            if (res.code != 0) {
              return;
            }
            if (pageNo == 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                pageSize: pageSize,
                currentPage: pageNo,
              });
            }
            console.log(this.historyList);
            this.historyList = res.data.datas;
          });
      } else {
          this.$fly
              .post(waterHistoryListUrl, {
                  meterId: this.meterId, //表id
                  type: HYDROPOWER.WATT_HOUR_METER,
                  pageSize: pageSize, //页面大小
                  pageNo: pageNo, //页码
                  search: this.selectPut, //搜索框内容
              })
              .then((res) => {
                  console.log(res);
                  if (res.code != 0) {
                      return;
                  }
                  if (pageNo == 1) {
                      this.$vc.emit(this.$route.path, "pagination", "init", {
                          total: res.data.total || res.data.datas.length,
                          pageSize: pageSize,
                          currentPage: pageNo,
                      });
                  }
                  console.log(this.historyList);
                  this.historyList = res.data.datas;
              });
      }
    },
    //历史租客
    async getInformation(item) {
      this.InformLists = await this.$fly
        .post(hydropowerContractInformationUrl, {
          cid: item.cid,
          lesseeId:item.lesseeId
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          return res.data;
        });
      this.$CSDialog.alert({
        width: "700px",
        title: "历史业主/租客",
        messageHtml: `
                    <div class="comment-panel" style="--item-label: 160px;">
                        <div class="item right">
                            <div class="item-label">企业/个人名称</div>
                            <div>${this.InformLists.companyName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">合同时间</div>
                            <div>${
                                (this.InformLists.startDate || '-') +
                              "至" +
                                (this.InformLists.endDate || '-')
                            }</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人</div>
                            <div>${this.InformLists.deputyName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人手机号</div>
                            <div>${this.InformLists.deputyPhone}</div>
                        </div>
                    </div>
                `,
      });
    },
  },
};
</script>

<style scoped>
.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
